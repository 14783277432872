<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" :readonly="$authorised('con/settings/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :is-blue="true">
		
			<p>The organisation wide settings will be used when a convention setting is not provided.</p>
			
		</app-content-box>

		<app-content-box :readonly="$authorised('con/settings/access', 'read')">

			<app-input-image label="Logo" v-model="model.logo" :validation="$v.model.logo" />
			<app-input-text label="Website" placeholder="Enter full URL including https://" v-model="model.website" :validation="$v.model.website" />
			<app-input-text label="City" placeholder="Enter city name" v-model="model.location.city" :validation="$v.model.location.city" />
			<app-input-text label="Building" placeholder="Enter building name (e.g. Plaza Hotel)" v-model="model.location.building" :validation="$v.model.location.building" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			settings: true,
			forceModelLoad: true,
			readonly: this.$authorised('con/settings/access', 'read'),
			model: {
				logo: '',
				website: '',
				location: {
					city: '',
					building: ''
				}
			}
		}

	},

	validations: {
		model: {
			location: {}
		}
	},

	computed: {

		defaultSettings: function() {

			return []

		}

	}

}

</script>

<style scoped>

</style>